<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <!-- 弹窗页面 -->
    <div class="popup_main">
        <div class="popup_main_top">
            <span class="popup_main_top_font">选择权属</span>
            <div class="popup_main_top_search">
                <el-form
                    label-position="left"
                    :inline="true"
                    :model="searchForm"
                    label-width="auto"
                >
                    <el-form-item label="作品名称">
                        <el-input
                            class="search-input"
                            @focus="focus"
                            @blur="focus"
                            @keyup.enter.native="searchInput"
                            v-model="searchForm.key_word"
                            placeholder="请输入作品名称"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="ID">
                        <el-input
                            class="search-input"
                            v-model="searchForm.id"
                            placeholder="请输入ID"
                            @focus="focus"
                            @blur="focus"
                            @keyup.enter.native="searchInput"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="作品类型">
                        <el-select
                            class="search-input"
                            @change="searchInput"
                            v-model="searchForm.works_category"
                            placeholder="请选择"
                        >
                            <el-option label="全部" value=""></el-option>
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select
                            class="search-input"
                            @change="searchInput"
                            v-model="searchForm.owner_status"
                            placeholder="请选择"
                        >
                            <el-option label="全部" value=""></el-option>
                            <el-option
                                v-for="(item, key) in ownerStatusMap"
                                :key="key"
                                :label="item"
                                :value="key"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-button
                    type="primary"
                    plain
                    class="search-btn"
                    @click="searchInput"
                >
                    <i class="el-icon-search"></i>
                    搜索
                </el-button>
            </div>
        </div>
        <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            v-loading="tableLoading"
        >
            <el-table-column width="140" prop="works_name" label="作品名称">
                <template slot-scope="{ row }">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="row.works_name"
                        placement="right"
                    >
                        <div class="table_works_name">
                            {{ row.works_name }}
                        </div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="works_category" label="作品类型"> </el-table-column>
            <el-table-column prop="complaint_num" label="投诉次数"> </el-table-column>
            <el-table-column prop="cp_id" label="ID"> </el-table-column>
            <el-table-column prop="ownership_status" label="状态">
                <template slot-scope="{ row }">
                    <div :class="row.ownership_status | changeColor">
                        {{ row.ownership_status }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" min-width="100">
                <template slot-scope="scope">
                    <p class="selectpop" @click="gopop(scope.row)">选择</p>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            v-if="cpage <= 10"
            background
            layout="prev, pager, next"
            :page-size="showRows"
            :current-page="cpage"
            :total="limitedTotal"
            @current-change="handlePageChange"
        >
        </el-pagination>
        <div v-else class="table-pagination">
            <i
                class="el-icon-arrow-left"
                @click="handlePageChange('sub')"
                v-loading="tableLoading"
            >
            </i>
            <span class="pagination-num">{{ cpage }}</span>
            <i
                v-loading="tableLoading"
                :class="[
                    'el-icon-arrow-right',
                    { 'icon-disabled': lastPage === cpage }
                ]"
                @click="handlePageChange('add')"
            >
            </i>
        </div>
    </div>
</template>

<script>
import { keyword, ownership } from '@/api/getData.js';
import { getType2 } from '@/utils/public.js';
import { Loading } from 'element-ui';

export default {
    name: 'popownership',
    components: {},
    data() {
        return {
            tableData: [],
            off: true,
            showRows: 10, // 反馈记录--每页显示的条数
            cpage: 1, // 反馈记录--当前页
            total: 0, // 反馈记录-总条数
            lastPage: 0, // 反馈记录--总页数
            searchForm: {
                key_word: '',
                works_category: '',
                id: '',
                owner_status: '',
                update_time: '',
                lastPageNo: 0, // 反馈记录--上次页数
            }, // 搜索条件
            options: [], // 作品类型列表
            tableLoading: false,
            ownerStatusMap: {
                1: '审核中',
                2: '已通过',
                3: '已过期',
                4: '未通过',
            }, // 审核状态map
        };
    },
    methods: {
        /**
         * @description: 处理页码改变
         * @param {*} value 页码/类型
         * @return {*}
         */
        handlePageChange(value) {
            if (typeof value === 'string' && (value === 'add' || value === 'sub')) {
                // 如果表格正在加载或在“add”操作时已到达最后一页，则退出
                if (this.tableLoading || (value === 'add' && this.lastPage <= this.cpage)) {
                    return;
                }
                // 根据 value 的值进行页数的增减
                this.cpage += value === 'add' ? 1 : -1;
            } else {
                // 如果 value 是数字，则直接设置页数
                this.cpage = value;
            }
            this.inject({
                ...this.searchForm,
                page: this.cpage,
                size: this.showRows,
            });
        },
        /**
         * @description: 换页
         * @param {*} e
         * @return {*}
         */
        changeC(e) {
            this.cpage = e;
            this.inject({
                ...this.searchForm,
                page: e,
                size: this.showRows,
            });
        },
        getTime(date) {
            var json_date = new Date(date).toJSON();
            let str = new Date(+new Date(json_date) + 8 * 3600 * 1000).toISOString();
            return str
                .replace(/T/g, ' ')
                .replace(/\.[\d]{3}Z/, '')
                .split(' ')[0];
        },
        gopop(pop) {
            let router = this.$route.path;
            let arr1 = ['未通过', '已过期'];
            if (arr1.indexOf(pop.ownership_status) !== -1) {
                let obj = {
                    updata: true,
                    cp_id: pop.cp_id,
                    type: true,
                };
                sessionStorage.setItem('own', JSON.stringify(obj));
                if (router === '/ownership') {
                    this.$router.go(0);
                } else {
                    this.$router.replace({
                        path: '/ownership',
                        params: {
                            id: pop.cp_id,
                        },
                    });
                }
            } else {
                ownership({
                    id: pop.cp_id,
                }).then((res) => {
                    let fileList1 = [];
                    let fileList2 = [];
                    if (res.data.owner_type !== 1) {
                        res.data.owner_url.forEach((item) => {
                            fileList1.push({
                                url: item,
                            });
                        });
                        res.data.authorization_url.forEach((item) => {
                            fileList2.push({
                                url: item,
                            });
                        });
                    }
                    let obj1 = {
                        updata: true,
                        cp_id: pop.cp_id,
                        arr: pop.works_certificate_url,
                        // 作者名称/笔名
                        pseudonym: res.data?.pseudonym,
                        authorizeEmail: res.data.email,
                        authorizeName: res.data.contact_name,
                        authorizeTel: res.data.mobile_phone,
                        pType: pop.owner_type,
                        prove: [],
                        fileList1,
                        fileList2,
                        time: [this.getTime(pop.works_start_date), this.getTime(pop.works_end_date)],
                        workName: pop.works_name,
                        workType: getType2(pop.works_category),
                    };
                    let obj = {
                        updata: true,
                        type: true,
                        cp_id: pop.cp_id,
                    };
                    sessionStorage.setItem('own', JSON.stringify(obj));
                    sessionStorage.setItem('ownership', JSON.stringify(obj1));
                    sessionStorage.setItem('active', 2);
                    this.$router.push({
                        path: '/complaint',
                    });
                });
            }
        },
        pop(data) {
            const obj = this.ownerStatusMap;
            for (let key in obj) {
                if (key === data + '') {
                    return obj[key];
                }
            }
        },
        focus() {
            this.off = !this.off;
        },
        gType(type) {
            let obj = this.$store.getters.getOptionsObj; // 获取作品类型列表
            for (var key in obj) {
                if (type + '' === key) {
                    return obj[key];
                }
            }
        },
        /**
         * @description: 查询权属列表
         * @param {*} params 请求参数
         * @return {*}
         */
        inject(params = {}) {
            this.tableLoading = true;
            keyword(params)
                .then((res) => {
                    const { data: { total } } = res;
                    const lastItem = res.data.records.at(-1);
                    this.searchForm.update_time = lastItem ? lastItem.update_time : '';
                    this.searchForm.lastPageNo = res?.data?.current || 0;
                    this.lastPage = res.data.pages;
                    res.data.records.forEach((item) => {
                        item.ownership_status = this.pop(item.ownership_status);
                        item.works_category = this.gType(item.works_category);
                    });
                    this.tableData = res.data.records;
                    this.total = total;
                })
                .finally(() => {
                    this.tableLoading = false;
                });
        },
        searchInput() {
            this.cpage = 1; // 查询时初始化当前页码
            const params = {
                ...this.searchForm,
                page: this.cpage,
                size: this.showRows,
            };
            this.tableLoading = true;
            // let loadingInstance = Loading.service();
            keyword(params).then((res) => {
                // loadingInstance.close();
                const { records, total } = res?.data || {};
                res.data.records.forEach((item) => {
                    item.ownership_status = this.pop(item.ownership_status);
                    item.works_category = this.gType(item.works_category);
                });
                this.tableData = records;
                this.total = total;
            })
            .finally(() => {
                this.tableLoading = false;
            });
        },
    },
    mounted() {
        this.inject({
            ...this.searchForm,
            page: 1,
            size: this.showRows,
        });
        this.options = this.$store.getters.getOptionsArr; // 获取作品类型列表
    },
    computed: {
        getclass() {
            return this.off ? 'el-input__icon el-icon-search' : 'el-input__icon el-icon-search on';
        },
        limitedTotal() {
            return this.total > 101 ? 101 : this.total;
        },
    },
    filters: {
        changeColor(newValue) {
            switch (newValue) {
                case '审核中':
                    return 'auditing';
                case '已通过':
                    return 'approved';
                case '已过期':
                    return 'expired';
                default:
                    return 'rejected';
            }
        },
    },
    watch: {
        search(newValue, oldValue) {
            if (newValue.length === 0) {
                this.inject({
                    ...this.params,
                    page: this.cpage,
                    size: this.showRows,
                });
            }
        },
    },
};
</script>
<style scoped lang='less'>
.table-pagination {
    margin: 28px 0 0 10px;
    font-weight: bold;
    font-size: 13px;
    ::v-deep .el-icon-arrow-right {
        height: 26px;
        line-height: 26px;
        width: 28px;
        background: transparent;
        border: 1px solid #e2e5eb;
        border-radius: 6px;
        margin: 0 5px;
        cursor: pointer;
    }
    ::v-deep .el-icon-arrow-left {
        height: 26px;
        line-height: 26px;
        width: 28px;
        background: transparent;
        border: 1px solid #e2e5eb;
        border-radius: 6px;
        margin: 0 5px;
        cursor: pointer;
    }
    .pagination-num {
        display: inline-block;
        height: 26px;
        line-height: 26px;
        width: 28px;
        background: transparent;
        border: 1px solid #e2e5eb;
        border-radius: 6px;
        margin: 0 5px;
        color: #2d63e0;
        background: rgba(45, 99, 224, 0.14);
        border: 1px solid #2d63e0;
        border-radius: 5px;
        font-size: 13px;
    }
    .icon-disabled {
        color: #C0C4CC;
        background: #fafbfb;
    }
    .icon-disabled:hover {
        opacity: none;
        cursor: not-allowed;
    }
    ::v-deep .circular {
        width: 20px;
        height: 20px;
    }
    ::v-deep .el-loading-mask {
        top: 21px;
    }
}
::v-deep .el-table:before {
    height: 0px;
}
::v-deep .el-table .cell {
    padding-left: 24px !important;
    text-align: left;
}
::v-deep .el-pagination .btn-prev:disabled {
    background: #fafbfb !important;
}
::v-deep .el-pagination .btn-next:disabled {
    background: #fafbfb !important;
}
.el-table {
    height: 520px !important;
    border-bottom: 1px solid #e6e6eb;
    overflow-y: scroll;
}
[class*=' el-icon-'],
[class^='el-icon-']:hover {
    color: #c0c4cc;
}
::v-deep .el-table th,
.el-table tr {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.table_works_name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
::v-deep .el-table td,
.el-table th.is-leaf {
    border-bottom: none;
}
::v-deep .el-table th > .cell {
    padding-left: 24px;
}
.el-pagination button {
    height: 30px;
}
.el-pagination span:not([class*='suffix']) {
    height: 30px;
}
::v-deep .el-pagination [class*=' el-icon-'],
[class^='el-icon-']:hover {
    color: #c0c4cc;
}
::v-deep .el-table tbody tr:hover > td {
    background-color: #e6edff !important;
}
::v-deep .has-gutter {
    color: #27272d;
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
    background: #f5f7fc;
}
::v-deep .el-table--striped .el-table__header-wrapper th {
    background: #f5f7fc;
    color: #27272d;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: rgba(45, 99, 224, 0.14);
    border: 1px solid #2d63e0;
    border-radius: 5px;
    color: #2d63e0;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #2d63e0;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
    background: transparent;
    border: 1px solid #e2e5eb;
    border-radius: 6px;
}
::v-deep .el-pagination.is-background .btn-next {
    background: transparent;
    border: 1px solid #e2e5eb;
    border-radius: 6px;
}
::v-deep .el-pagination.is-background .btn-prev {
    background: transparent;
    border: 1px solid #e2e5eb;
    border-radius: 6px;
}
::v-deep .el-pagination.is-background .el-pager li {
    background: transparent;
    border: 1px solid #e2e5eb;
    border-radius: 6px;
}
.popup_main {
    height: auto;
    width: 864px;
    margin: auto;
    overflow: hidden;
    background-color: #fff;
    border-radius: 4px;
}
.popup_main_top {
    width: 770px;
    height: 150px;
    display: flex;
    justify-content: space-between;
    margin-top: -3px;
}
.popup_main_top span {
    height: 20px;
    text-align: left;
    margin-top: 27px;
    text-indent: 20px;
    font-weight: bold;
}
.popup_main_top_font {
    font-size: 20px;
    color: #1e2024;
    line-height: 20px;
    height: 20px;
}

.el-table {
    width: 816px;
    height: auto;
}
.popup_main_top_search {
    margin-top: 20px;
    flex: 1;
    position: relative;
}
.search-btn {
    width: 93px;
    height: 40px;
    font-size: 16px;
    background: #2d63e0;
    color: #fff;
    position: absolute;
    right: -91px;
    top: 62px;
}
.search-btn:hover {
    background: #2d63e0;
    cursor: pointer;
    opacity: 0.8;
}

.popup_main_input {
    width: 160px;
    height: 32px;
    margin-top: 22px;
    position: relative;
    left: 0px;
}

.el-pagination {
    margin-top: 28px;
}

.selectpop {
    font-size: 14px;
    color: #2d63e0;
    line-height: 14px;
}
.selectpop:hover {
    cursor: pointer;
}

.auditing {
    color: #fa8b4b;
}

.approved {
    color: #41cca2;
}

.rejected {
    color: #e63232;
}

.expired {
    color: #767981;
}
.el-input__icon.on {
    color: #2d63e0;
}
.el-input__icon:hover {
    cursor: pointer;
    color: #2d63e0;
}
.search-input {
    width: 192.5px;
}
</style>