<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <!-- 下一步按钮 -->
    <div class="form_submit">
        <el-form>
            <el-form-item>
                <el-button v-show="btn1 === 0" class="button1" @click="backForm('ruleForm')">上一步</el-button>
                <el-button v-show="btn2 === 0" class="next" type="primary" @click="submitForm">下一步</el-button>
                <el-button v-show="btn3 === 0" class="next" type="primary" @click="subm">提交投诉</el-button>
                <el-button v-show="btn4 === 0" type="primary" @click="edit">编辑</el-button>
                <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { keyword } from '@/api/getData.js';
export default {
    name: 'nexttick',
    props: {
        btn1: {
            type: Number,
            required: false,
            default: 1,
        },
        btn2: {
            type: Number,
            required: false,
            default: 0,
        },
        btn3: {
            type: Number,
            required: false,
            default: 0,
        },
        btn4: {
            type: Number,
            required: false,
            default: 0,
        },
        tourl: {
            type: String,
            required: false,
            default: '/',
        },
    },
    data() {
        return {};
    },
    methods: {
        activated() {},
        subm() {
            this.$emit('subm');
        },
        backForm(ruleForm) {
            let own = sessionStorage.getItem('own');
            if (own) {
                own = JSON.parse(own);
            }
            if (this.$route.name === 'complaint') {
                if (own.updata) {
                    this.$router.push({
                        path: '/ownership',
                    });
                } else {
                    this.$router.push({
                        path: '/hasownership',
                    });
                }
            } else if (this.$route.name === 'hasownership') {
                this.$router.push({
                    path: '/subject',
                });
            } else if (this.$route.name === 'ownership') {
                keyword({
                    lastPageNo: 0,
                    key_word: '',
                    owner_type: 0,
                    page: 1,
                    size: 8,
                }).then((res) => {
                    if (res?.data?.records?.length) {
                        this.$router.push({
                            path: '/hasownership',
                        });
                    } else {
                        this.$router.push({
                            path: '/noownership',
                        });
                    }
                });
            } else {
                this.$router.go(-1);
            }
        },
        submitForm(formName) {
            this.$emit('nextTick');
        },
        edit() {
            this.$emit('edit');
        },
    },
};
</script>
<style scoped lang='less'>
.form_submit {
    padding: 48px 0;
}
.el-button {
    background: #2d63e0;
    border-radius: 4px;
    height: 40px;
    color: #ffffff;
}
.el-button.next:hover {
    background: #326efa;
}
.el-button.next:active {
    background: #2858c7;
}
::v-deep .el-button + .el-button {
    margin-left: 24px;
}
::v-deep .el-form {
    .el-button--default.el-button {
        background: #f5f7fc;
        color: #2d63e0;
        border: none;
        &:hover {
            color: #326efa;
        }
        &:active {
            color: #2858c7;
        }
    }
}
</style>